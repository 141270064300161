/* eslint-disable no-constant-condition */
import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@mui/styles';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';

import { Box, Button, Container, Dialog } from '@mui/material';
import classNames from 'classnames';
import { useMatch, useLocation, NavLink, Link } from 'react-router-dom';

import colorVariables from '../../styles/variables';

import { topNavZIndex } from '../../styles/styles';

import theme from '../../styles/theme';
import { isLoggedIn } from '../../services/user-service';

import styles from './site.module.css';
import TlLogoWhite from './svg/tl-logo-white';
import TlLogoBlack from './svg/tl-logo-black';

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: colorVariables.white,
    flexGrow: 1,
    zIndex: topNavZIndex,
    position: 'sticky',
    top: '0',
  },
  appBar: {
    position: 'absolute',
    padding: '8px 0',
    '&.MuiPaper-root': {
      boxShadow: 'none',
    },
  },
  toolBar: {
    height: '46px',
    minHeight: '46px',
    padding: '0 20px',

    [theme.breakpoints.down('md')]: {
      padding: '0 4px',
    },
  },
  logo: {
    margin: '0px 16px 0 0',
    height: '32px',
  },
  logoContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  serviceName: {
    whiteSpace: 'nowrap',
    color: colorVariables.composerLinkBlack,
    '&:hover': {
      color: '#5a24fb',
    },

    '&.active': {
      color: '#5a24fb',
    },
  },
  serviceNameWhite: {
    whiteSpace: 'nowrap',
    color: colorVariables.white,
    '&:hover': {
      color: '#5a24fb',
    },

    '&.active': {
      color: '#5a24fb',
    },
  },
  f1: {
    flex: 1,
  },
}));

const HomeTopNav = ({ scrollY }) => {
  const classes = useStyles();
  const { pathname } = useLocation();
  const csRouteActive = useMatch('/creative-studio');
  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const useWhiteStyles = csRouteActive && (!scrollY || scrollY <= 0);
  const menuIconColor = useWhiteStyles ? '#FFF' : '#5A24FB';

  const getAppBarStyles = () => {
    if (!scrollY)
      return {
        backgroundColor: 'transparent',
        backdropFilter: 'none',
      };

    const maxOpacity = 85;
    const maxSaturation = 180;
    const maxBlur = 20;
    const opacity = Math.min(maxOpacity, (scrollY / 50) * maxOpacity);
    const saturation = Math.min(maxSaturation, (scrollY / 50) * maxSaturation);
    const blur = Math.min(maxBlur, (scrollY / 50) * maxBlur);

    return {
      backgroundColor: `rgb(255 255 255 / ${opacity}%)`,
      backdropFilter: `saturate(${saturation}%) blur(${blur}px)`,
      WebkitBackdropFilter: `saturate(${saturation}%) blur(${blur}px)`,
    };
  };

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const pages = [
    { page: 'Stream Composer', location: '/stream-composer' },
    { page: 'Stream Extension', location: '/stream-extension' },
    { page: 'Creative Studio', location: '/creative-studio' },
    { page: 'Demos', location: '/demo/live-sports/pip' },
  ];

  const menuPages = [...pages, { page: 'Login', location: '/login' }];

  const renderLoginSignUp = () => {
    if (isLoggedIn()) {
      return (
        <Link to="https://console.transmit.live">
          <Button className={classNames(styles.button, styles.buttonPurple)}>Dashboard</Button>
        </Link>
      );
    }
    return (
      <Box sx={{ display: 'flex', gap: '10px' }}>
        <Box>
          <Link to="/login">
            <Button disableElevation className={classNames(styles.button, styles.buttonPurple)}>
              Log in
            </Button>
          </Link>
        </Box>
      </Box>
    );
  };

  return (
    <div className={classes.root}>
      <AppBar position="static" classes={{ root: classes.appBar }} style={getAppBarStyles()}>
        <Container maxWidth="xl">
          <Toolbar disableGutters classes={{ root: classes.toolBar }}>
            <Link to="/" className={classes.logoContainer}>
              {useWhiteStyles ? <TlLogoWhite /> : <TlLogoBlack />}
            </Link>

            <Box
              sx={{
                flexGrow: 1,
                display: { xs: 'none', md: 'flex' },
                color: {},
                justifyContent: 'center',
                gap: { md: 2, lg: 4 },
              }}
            >
              {pages.map(({ page, location }) => {
                const linkClasses = useWhiteStyles ? [classes.serviceNameWhite] : [classes.serviceName];
                const isExtensionDemoLink = pathname.includes('/extension-demo') && location === '/demo';

                return (
                  <NavLink
                    key={page}
                    to={location}
                    className={({ isActive }) => {
                      return classNames(linkClasses, isActive || isExtensionDemoLink ? 'active' : '');
                    }}
                  >
                    {page}
                  </NavLink>
                );
              })}
            </Box>

            <Box className={styles.f1} sx={{ display: { xs: 'flex', md: 'none' } }} />

            <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
              <Box>
                <Button
                  className={classNames(styles.menuButton, { [styles.menuButtonWhite]: useWhiteStyles })}
                  size="large"
                  aria-haspopup="true"
                  onClick={handleOpenNavMenu}
                  aria-label="menu"
                >
                  {anchorElNav ? (
                    <CloseIcon style={{ color: menuIconColor }} />
                  ) : (
                    <MenuIcon style={{ color: menuIconColor }} />
                  )}
                </Button>
                <Dialog open={Boolean(anchorElNav)} onClose={handleCloseNavMenu} hideBackdrop>
                  <div className={styles.navMenu}>
                    {menuPages.map(({ page, location }) => (
                      <Link
                        key={location}
                        to={location}
                        onClick={() => {
                          setAnchorElNav(null);
                        }}
                      >
                        {page}
                      </Link>
                    ))}
                  </div>
                </Dialog>
              </Box>
            </Box>

            <Box sx={{ display: { xs: 'none', md: 'flex' } }}>{renderLoginSignUp()}</Box>
          </Toolbar>
        </Container>
      </AppBar>
    </div>
  );
};

HomeTopNav.defaultProps = {};

HomeTopNav.propTypes = {
  scrollY: PropTypes.number.isRequired,
};

export default HomeTopNav;
