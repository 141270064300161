import axios from 'axios';
import { deformatAdDuration } from '../utils/format';

export const inventoryTypes = {
  interstitial: 'interstitial',
  overlay: 'overlay',
  pip: 'pip',
  squeezeBack: 'squeezeBack',
};

export const inventoryTypesToDesc = {
  [inventoryTypes.pip]: 'Picture-in-Picture',
  [inventoryTypes.interstitial]: 'Interstitial',
  [inventoryTypes.overlay]: 'Overlay',
  [inventoryTypes.squeezeBack]: 'Squeeze Back',
};

export const targetingAdTypeToDesc = {
  preRoll: 'Pre-Roll',
  [inventoryTypes.pip]: 'Picture-in-Picture',
  [inventoryTypes.interstitial]: 'Full Screen',
  [inventoryTypes.overlay]: 'Overlay',
  [inventoryTypes.squeezeBack]: 'Squeeze Back',
};

export const NO_AUDIO_INVENTORY_TYPES = [inventoryTypes.overlay, inventoryTypes.squeezeBack];

export const TRANSMIT_AD_SERVER = 'Transmit';

export const getAdsByAccount = async (accountId, search) => {
  const url = `${SERVICE_URL}/api/v1/account/${accountId}/ads${search}`;
  return axios.get(url).then((response) => response.data);
};

export const getAdsByAccountAdvertiser = async (accountId, advertiserId, search) => {
  const url = `${SERVICE_URL}/api/v1/account/${accountId}/advertiser/${advertiserId}/ads${search}`;
  return axios.get(url).then((response) => response.data);
};

export const postAdsIdsByAccount = async (accountId, adIds, search) => {
  const idQuery = { ids: adIds };
  const url = `${SERVICE_URL}/api/v1/account/${accountId}/ads/by_ids${search}`;
  return axios.post(url, idQuery).then((response) => response.data);
};

export const postAdsIdsByAccountAdvertiser = async (accountId, advertiserId, adIds, search) => {
  const idQuery = { ids: adIds };
  const url = `${SERVICE_URL}/api/v1/account/${accountId}/advertiser/${advertiserId}/ads/by_ids${search}`;
  return axios.post(url, idQuery).then((response) => response.data);
};

export const getAd = async ({ accountId, adId }) => {
  const url = `${SERVICE_URL}/api/v1/account/${accountId}/ad/${adId}`;
  return axios.get(url).then((response) => response.data);
};

export const updateAd = async ({ accountId, advertiserId, ad, reencode = false }) => {
  const url = `${SERVICE_URL}/api/v1/account/${accountId}/advertiser/${advertiserId}/ad?reencode=${reencode}`;
  return axios.put(url, ad).then((response) => response.data);
};

export const updateBulkEditAds = async ({ accountId, advertiserId, adIds, bulkEditProps, reencode = false }) => {
  const accountStr = `account/${accountId}`;
  const url = `${SERVICE_URL}/api/v1/${accountStr}/advertiser/${advertiserId}/ads/bulk-edit?reencode=${reencode}`;
  return axios.put(url, { adIds, bulkEditProps }).then((response) => response.data);
};

export const archiveAd = async ({ accountId, ad, archive }) => {
  const archivedAd = { ...ad, duration: deformatAdDuration(ad.duration), archived: archive };
  return updateAd({ accountId, advertiserId: ad.advertiserId, ad: archivedAd });
};

/**
 * Fetches the Ad by ID to ensure we have the latest data; then duplicates it.
 * - For the new ad, we set the active and archived properties to FALSE
 * - No error handling done here; an error could be returned from the fetch or the upsert.
 * @param {String} accountId id of the account the ad belongs to
 * @param {String} adId id of the original ad to be duplicated
 * @returns {Promise<Object>} returns the promise for the upsert API call for the new, duplicated line item
 */
export const duplicateAd = async (accountId, adId) => {
  const ad = await getAd({ accountId, adId });

  const { _id, advertiserId, name, active, archived, ...rest } = ad;

  const newCreative = {
    ...rest,
    name: `${name} Copy`,
    archived: false,
  };

  return updateAd({ accountId, advertiserId, ad: newCreative, reencode: false });
};
