import axios from 'axios';

export const removeNullValues = (object) => {
  for (const property in object) {
    // eslint-disable-next-line no-param-reassign
    if (object[property] === null) delete object[property];
  }
  return object;
};

export const virtualButtonClick = (button) => {
  button.focus();
  button.click();
  setTimeout(() => button.blur(), 250);
};

export const removeLeadingSlash = (string) => {
  return string.replace(/^\/?/, '');
};

export const capitalizedWord = (word) => {
  const splitWord = word.split(' ');
  return splitWord.map((w) => w.charAt(0).toUpperCase() + w.slice(1)).join(' ');
};

export const noAuthPut = async (url, file, config) => {
  const noAuthAxios = axios.create();
  delete noAuthAxios.defaults.headers.common.Authorization;

  return noAuthAxios.put(url, file, config);
};

export const noAuthGet = async (url, config) => {
  const noAuthAxios = axios.create();
  delete noAuthAxios.defaults.headers.common.Authorization;
  return noAuthAxios.get(url, config);
};

export const noAuthHead = async (url, config) => {
  const noAuthAxios = axios.create();
  delete noAuthAxios.defaults.headers.common.Authorization;
  return noAuthAxios.head(url, config);
};
