import { studioTopNavHeight, studioPrimaryColor } from './creative-studio/studio-variables';
import colorVariables from './variables';

export const topNavZIndex = 900;

export const contextOverlayZIndex = 700;

export const datePickerDialogZIndex = 1400;

export const chartFacetZIndex = 0;

export const dialogtFacetZIndex = 2500;

/** @type {import('@mui/styles').CSSProperties} */
export const textField = {
  minHeight: 70,

  '&.MuiFormControl-marginDense': {
    margin: '4px 0',
  },

  '& .MuiFormLabel-root.Mui-focused': {
    color: colorVariables.purple900,
  },

  '& .MuiInput-underline:after': {
    //borderBottom: `2px solid ${colorVariables.purple100}`,
  },

  '& .MuiFormHelperText-marginDense, .MuiFormHelperText-root': {
    marginLeft: 0,
    '&:first-letter': {
      textTransform: 'capitalize',
    },
  },

  '& .MuiChip-root': {
    backgroundColor: colorVariables.purple100,
  },
};

/** @type {import('@mui/styles').CSSProperties} */
export const autoComplete = {
  minHeight: 70,

  '&.MuiFormControl-marginDense': {
    margin: '4px 0',
  },

  '& .MuiFormLabel-root.Mui-focused': {
    color: colorVariables.purple900,
  },

  '& .MuiInput-underline:after': {
    borderBottom: `2px solid ${colorVariables.purple100}`,
  },

  '& .MuiFormHelperText-marginDense, .MuiFormHelperText-root': {
    marginLeft: 0,
    '&:first-letter': {
      textTransform: 'capitalize',
    },
  },

  '& .MuiChip-root': {
    backgroundColor: colorVariables.purple100,
  },
};

/** @type {import('@mui/styles').CSSProperties} */
export const checkBox = {
  color: 'rgba(0, 0, 0, 0.54)',
  '& .MuiIconButton-colorSecondary': {
    color: colorVariables.purple900,
  },
};

/** @type {import('@mui/styles').CSSProperties} */
export const twoCol = {
  display: 'flex',
  justifyContent: 'space-between',
  '& div:first-child': {
    padding: '0 20px 0 0',
  },
};

/** @type {import('@mui/styles').CSSProperties} */
export const threeCol = {
  display: 'flex',
  justifyContent: 'space-between',
  '& div:first-child': {
    padding: '0 20px 0 0',
  },
  '& div:nth-child(2)': {
    padding: '0 20px 0 0',
  },
};

/** @type {import('@mui/styles').CSSProperties} */
export const newEntityButton = {
  color: colorVariables.white,
  fontSize: 14,
  fontWeight: 400,
  textTransform: 'none',
  maxHeight: 32,
  backgroundColor: colorVariables.black,
  '&:hover': {
    backgroundColor: colorVariables.grey500,
  },

  '& .MuiButton-iconSizeMedium': {
    marginRight: 3,
    '& .MuiSvgIcon-root': {
      fontSize: 18,
    },
  },
};

/** @type {import('@mui/styles').CSSProperties} */
export const editLink = {
  verticalAlign: 'top',
  margin: '0px 0px 0 4px',
  '& .MuiSvgIcon-root': {
    margin: '1px 0 0 0px',
    width: 18,
    height: 18,
    color: colorVariables.black,
  },
};

/** @type {import('@mui/styles').CSSProperties} */
export const entityListContainer = {
  margin: '20px 40px',
};

/** @type {import('@mui/styles').CSSProperties} */
export const entityListWrapper = {
  width: '100%',
};

/** @type {import('@mui/styles').CSSProperties} */
export const pagination = {
  padding: '15px 0 50px 0',
};

/** @type {import('@mui/styles').CSSProperties} */
export const listTitle = {
  fontSize: '24px',
  fontWeight: 400,
};

/** @type {import('@mui/styles').CSSProperties} */
export const listHeader = {
  display: 'flex',
  margin: '4px 0px 16px 4px',
};

/** @type {import('@mui/styles').CSSProperties} */
export const topControlsWrapper = {
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  margin: '0 0 20px 0',
};

/** @type {Object<string, import('@mui/styles').CSSProperties>} */
export const entityHeaderStyles = {
  entityHeader: {
    display: 'flex',
    padding: '5px 0px 14px 0px',
    flexGrow: 1,
    borderBottom: `solid 1px ${colorVariables.lightGrey}`,
    zIndex: topNavZIndex,
    position: 'sticky',
    top: '233px',
    backgroundColor: colorVariables.white,
    minWidth: '640px',
    flexDirection: 'column',
  },
  entityHeaderUnderSubNav: {
    display: 'flex',
    padding: '5px 0px 14px 0px',
    flexGrow: 1,
    borderBottom: `solid 1px ${colorVariables.lightGrey}`,
    zIndex: topNavZIndex,
    position: 'sticky',
    top: '175px',
    backgroundColor: colorVariables.white,
    minWidth: '640px',
    flexDirection: 'column',
  },
  entityHeaderNameContainer: {
    display: 'flex',
    alignItems: 'baseline',
  },
  entityHeaderLabel: {
    fontSize: '24px',
    lineHeight: '28px',
    margin: '0px 8px 0px 0px',
    color: colorVariables.black,
    '&::after': {
      content: '":"',
    },
  },
  entityHeaderName: {
    fontSize: '24px',
    lineHeight: '28px',
    color: colorVariables.black,
    fontWeight: 600,
  },
  entityHeaderDateContainer: {
    display: 'flex',
  },
  entityHeaderDate: {
    display: 'flex',
    margin: '0px 0px 0px 12px',
  },
  entityHeaderDateLabel: {
    fontSize: '13px',
    lineHeight: '28px',
    color: colorVariables.textGrey,
    margin: '0px 6px 0px 0px',
    '&::after': {
      content: '":"',
    },
  },
  entityHeaderDateValue: {
    fontSize: '13px',
    lineHeight: '28px',
    color: colorVariables.textGrey,
    margin: '0px 32px 0px 0px',
  },
  standardHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
  },
  headerChildren: {
    display: 'flex',
  },
};

/** @type {import('@mui/styles').CSSProperties} */
export const summaryStatContainer = {
  justifyContent: 'flex-end',
  margin: '0px 0px 0px 0px',
  position: 'absolute',
  right: 0,
  top: 0,
};

/** @type {import('@mui/styles').CSSProperties} */
export const actionCell = {
  zIndex: 1,
  position: 'sticky',
  left: 0,
  backgroundColor: 'white',
};

/** @type {import('@mui/styles').CSSProperties} */
export const titleCell = {
  '&.MuiTableCell-root': {
    color: `${colorVariables.black} !important`,
  },
  fontWeight: 500,
  backgroundColor: 'white',
  position: 'sticky',
  left: 0,
  zIndex: 1,
};

/** @type {import('@mui/styles').CSSProperties} */
export const facetFiltersRow = {
  display: 'flex',
  overflow: 'scroll',
  '& > div:last-child': {
    padding: '0px 128px 0px 0px',
  },
  '&:after': {
    content: '""',
    position: 'absolute',
    right: 0,
    top: 0,
    bottom: 0,
    left: 0,
    pointerEvents: 'none',
    background: 'linear-gradient(to right, rgba(255,255,255,0) 90%, rgba(255,255,255,1) 100%)',
    width: '100%',
    height: '100%',
  },
};

/** @type {import('@mui/styles').CSSProperties} */
export const headerDate = {
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
};

/** @type {import('@mui/styles').CSSProperties} */
export const runningChip = {
  backgroundColor: '#d2f9cd',
  color: '#007846',
  border: '1px solid #b0e9ac',
  fontSize: 11,
  fontWeight: 600,
  height: 26,
};

/** @type {import('@mui/styles').CSSProperties} */
export const notRunningChip = {
  backgroundColor: '#ffdeeb',
  color: '#841825',
  border: '1px solid #e5c1d2',
  fontSize: 11,
  fontWeight: 600,
  height: 26,
};

/** @type {import('@mui/styles').CSSProperties} */
export const openMarketplace = {
  color: colorVariables.green200,
  fontSize: 26,
};

/** @type {import('@mui/styles').CSSProperties} */
export const notOpenMarketplace = {
  color: colorVariables.red100,
  fontSize: 26,
};

/** @type {import('@mui/styles').CSSProperties} */
export const ssai = {
  backgroundColor: colorVariables.grey200,
  fontSize: 11,
  height: 26,
  width: 65,
};

/** @type {import('@mui/styles').CSSProperties} */
export const vast = {
  backgroundColor: colorVariables.grey200,
  fontSize: 11,
  height: 26,
  width: 65,
};

/** @type {import('@mui/styles').CSSProperties} */
export const coreButton = {
  fontSize: 11,
  height: 20,
  width: 60,
  color: colorVariables.white,
  margin: '0px 4px',
};

/** @type {import('@mui/styles').CSSProperties} */
export const saveButton = {
  backgroundColor: colorVariables.black,
  '&:hover': {
    backgroundColor: colorVariables.lightBlack,
  },
  '& .MuiCircularProgress-svg': {
    color: colorVariables.white,
  },
  '&.Mui-disabled': {
    color: colorVariables.white,
    backgroundColor: colorVariables.grey400,
  },
};

/** @type {import('@mui/styles').CSSProperties} */
export const cancelButton = {
  backgroundColor: colorVariables.red500,
  '&:hover': {
    backgroundColor: colorVariables.red300,
  },
};

/** @type {Object<string, import('@mui/styles').CSSProperties>} */
export const navLinkStyles = {
  navContainer: {
    display: 'flex',
    height: studioTopNavHeight,
    gap: '35px',
    '@media (max-width: 1280px)': {
      gap: '15px',
    },
  },
  navLink: {
    fontSize: 14,
    color: colorVariables.grey500,
    paddingBottom: '10px',
    borderBottom: '2px solid transparent',
  },
  activeNavLink: {
    fontSize: 14,
    color: colorVariables.black,
    borderBottom: `2px solid ${colorVariables.purple700}`,
  },
  navLinkLabel: {
    display: 'flex',
    alignItems: 'center',
    gap: '2px',
    height: studioTopNavHeight,
  },
};

/** @type {import('@mui/styles').CSSProperties} */
export const actionBar = {
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  position: 'sticky',
  bottom: 0,
  borderTop: `1px solid ${colorVariables.grey300}`,
  padding: '20px 24px',
  backgroundColor: colorVariables.white,
  zIndex: 99,
};

/** @type {import('@mui/styles').CSSProperties} */
export const baseButton = {
  padding: '6px 24px',
  textTransform: 'none',
  borderRadius: '8px',
  fontSize: '14px',
  marginLeft: '12px',
  fontWeight: 400,
};

/** @type {import('@mui/styles').CSSProperties} */
export const smallButton = {
  padding: '0 16px',
  height: '36px',
};

/** @type {import('@mui/styles').CSSProperties} */
export const smallIconButton = {
  height: '36px',
  width: '36px',
  padding: 0,
};

/** @type {import('@mui/styles').CSSProperties} */
export const keyButton = {
  backgroundColor: studioPrimaryColor,
  color: colorVariables.white,
  '&:hover': {
    backgroundColor: 'rgb(62, 25, 175)',
  },
  '&.Mui-disabled': {
    backgroundColor: colorVariables.grey200,
  },
};

/** @type {import('@mui/styles').CSSProperties} */
export const secondaryButton = {
  color: colorVariables.black,
  backgroundColor: colorVariables.grey100,
  '&:hover': {
    backgroundColor: colorVariables.grey300,
  },
};

/** @type {Object<string, import('@mui/styles').CSSProperties>} */
export const sectionStyles = {
  sectionTitle: {
    borderBottom: '1px solid rgb(224, 224, 224)',
    lineHeight: '34px',
    paddingBottom: '6px',
    fontSize: '16px',
    fontWeight: 600,
  },
  editSection: {
    marginBottom: '34px',
    marginTop: '8px',
  },
  editSectionRow: {
    display: 'flex',
    gap: '16px',
    paddingBottom: '20px',
    '& > *': {
      flex: 1,
    },
  },
};

/** @type {import('@mui/styles').CSSProperties} */
export const newFieldStyling = {
  fontSize: '14px',
  '& .MuiInputBase-root': {
    height: '40px',
    borderRadius: '8px',
    width: '100%',
    '& label.Mui-focused': {
      color: 'black',
    },
  },
  '& .MuiFormControl-root': {
    display: 'block',
    margin: '4px 0 4px 0',
  },
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      borderColor: colorVariables.purple900,
    },
  },
  '& .MuiInputBase-input': {
    fontSize: '14px',
  },
  '& .MuiInputLabel-root': {
    fontSize: '14px',
  },
  '& .MuiTextField-root': {
    margin: '4px 0 4px 0',
  },
  '& .MuiTabs-root': {
    '& .MuiTab-root': {
      color: studioPrimaryColor,
    },
    '& .MuiTabs-indicator, .MuiAvatar-root': {
      backgroundColor: studioPrimaryColor,
    },
  },
  '& .tl-daterange': {
    '& fieldset': {
      borderColor: 'rgba(0, 0, 0, 0.23)',
    },
    '& .tl-daterange-start-date': {
      margin: '0px',
      '& fieldset': {
        borderTopLeftRadius: '8px',
        borderBottomLeftRadius: '8px',
      },
    },
    '& .tl-daterange-end-date': {
      margin: '0px',
      '& fieldset': {
        borderTopRightRadius: '8px',
        borderBottomRightRadius: '8px',
      },
    },
    '& .tl-daterange-separator': {
      margin: '0px',
      padding: '10px 0px',
      borderColor: 'rgba(0, 0, 0, 0.23)',
    },
  },
};

/** @type {import('@mui/styles').CSSProperties} */
export const fieldLabel = {
  color: '#2F384C',
  fontSize: '14px',
  margin: '4px 0',
};

/** @type {import('@mui/styles').CSSProperties} */
export const chip = {
  color: colorVariables.textGrey,
  height: '28px',
  backgroundColor: colorVariables.white,
  '& .MuiChip-label': {
    paddingLeft: '5px',
  },
  '&&:hover': {
    backgroundColor: colorVariables.white,
    borderColor: colorVariables.grey500,
    color: colorVariables.black,
    '& $chipIcon': {
      borderColor: colorVariables.grey400,
    },
    '& $chipIconSolid': {
      backgroundColor: '#a0a6b1',
    },
  },
};

/** @type {import('@mui/styles').CSSProperties} */
export const activeChip = {
  borderColor: colorVariables.purple900,
  color: colorVariables.purple900,
  '&&:hover': {
    backgroundColor: colorVariables.white,
    borderColor: colorVariables.purple900,
    color: colorVariables.purple900,
    '&:hover $chipIconSolid': {
      backgroundColor: colorVariables.purple900,
    },
  },
  '& $chipIcon': {
    backgroundColor: colorVariables.purple900,
    border: 'none',
    stroke: colorVariables.white,
    color: colorVariables.white,
  },
};

/** @type {import('@mui/styles').CSSProperties} */
export const chipIcon = {
  width: '18px',
  height: '18px',
  borderRadius: '12px',
  marginRight: '6px',
  border: '1px solid #d2d2d2',
  boxSizing: 'border-box',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: '11px',
};

/** @type {import('@mui/styles').CSSProperties} */
export const chipIconSolid = {
  border: 'none',
  backgroundColor: '#b8bcc3',
};

/** @type {import('@mui/styles').CSSProperties} */
export const chipLabel = {
  display: 'flex',
  alignItems: 'center',
};

/** @type {import('@mui/styles').CSSProperties} */
export const stickyHeader = {
  position: 'sticky',
  top: '0px',
  backgroundColor: colorVariables.white,
  zIndex: topNavZIndex,
};

/** @type {import('@mui/styles').CSSProperties} */
export const renderOptionContainer = {
  display: 'flex',
  padding: '0px 10px 0px 10px',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  backgroundColor: colorVariables.white,
  '&:hover': {
    backgroundColor: colorVariables.menuItemGrey,
  },
  '&:hover $renderIcons': {
    visibility: 'visible',
  },
  cursor: 'pointer',
  whiteSpace: 'nowrap',
};

/** @type {import('@mui/styles').CSSProperties} */
export const selectedOptionContainer = {
  backgroundColor: colorVariables.menuItemSelectedPurple,
  '&:hover': {
    backgroundColor: colorVariables.menuItemSelectedHoverPurple,
  },
};

/** @type {import('@mui/styles').CSSProperties} */
export const renderOption = {
  maxWidth: '75%',
  flexGrow: 3,
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  fontSize: 14,
};

/** @type {import('@mui/styles').CSSProperties} */
export const adminReportContainer = {
  minHeight: 550,
  height: 'calc(100vh - 350px)',
};

/** @type {Object<string, import('@mui/styles').CSSProperties>} */
export const adminTableViewFilter = {
  selectorLabel: {
    display: 'none',
  },
};

export const adminDonutViewFilter = {
  selectorLabel: {
    fontSize: 11,
    marginLeft: 2,
    maxWidth: 170,
    overflow: 'hidden',
  },
};

export const REPORT_DATA_SELECTOR_WIDTH = 250;

/** @type {Object<string, import('@mui/styles').CSSProperties>} */
export const adminTimeSeriesViewFilter = {
  vizChipFilter: {
    borderTopLeftRadius: 6,
    borderBottomLeftRadius: 6,
    width: REPORT_DATA_SELECTOR_WIDTH,
    display: 'flex',
    justifyContent: 'space-between',
    textTransform: 'uppercase',
  },
  selectorLabel: {
    fontSize: 11,
    marginLeft: 2,
    maxWidth: 170,
    overflow: 'hidden',
  },
};

export const customFilterColors = {
  chipFilter: {
    backgroundColor: colorVariables.purple50,
    color: colorVariables.purple900,
    '&:hover': {
      color: colorVariables.white,
      backgroundColor: colorVariables.purple900,
    },
  },
  chipFilterActive: {
    backgroundColor: colorVariables.purple900,
    color: colorVariables.white,
    '&:hover': {
      color: colorVariables.white,
      backgroundColor: colorVariables.purple200,
    },
  },
};

/** @type {import('@mui/styles').CSSProperties} */
export const settingsHeader = {
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
  marginBottom: '20px',
  '& .MuiTypography-root': {
    lineHeight: '1',
  },
};

const TOP_NAV_SUB_NAV_HEIGHT = 364;
const TL_LIST_CONTAINER_HEIGHT_CLEARANCE = 216;

/** @type {import('@mui/styles').CSSProperties} */
export const tableContainer = {
  maxHeight: `calc(100vh - ${TOP_NAV_SUB_NAV_HEIGHT}px)`,
  overflow: 'scroll',
};

/** @type {import('@mui/styles').CSSProperties} */
export const tableHeaderContainer = {
  position: 'sticky',
  backgroundColor: 'white',
  top: 0,
  zIndex: 9,
};

export const tlListContainer = {
  maxHeight: `calc(100vh - ${TL_LIST_CONTAINER_HEIGHT_CLEARANCE}px)`,
  padding: '20px 40px',
};
