import React from 'react';
import { matchPath, Outlet, useLocation, useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { Button, useMediaQuery } from '@mui/material';

import styles from './site.module.css';
import tabbedStyles from './tabbed-section/tabbed-section.module.css';

const Demo = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [tabbedSectionOverflowing, setTabbedSectionOverflowing] = React.useState(false);
  const tabbedSectionRef = React.useRef(null);

  React.useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  // eslint-disable-next-line
  React.useEffect(() => {
    if (tabbedSectionRef?.current) {
      const observer = new ResizeObserver(() => {
        const element = tabbedSectionRef?.current;
        const hasOverflowingChildren =
          element.offsetHeight < element.scrollHeight || element.offsetWidth < element.scrollWidth;
        setTabbedSectionOverflowing(hasOverflowingChildren);
      });
      observer.observe(tabbedSectionRef.current);

      return () => {
        observer.disconnect();
      };
    }
  }, [tabbedSectionRef.current]);

  const unsupportedScreenExtensionDemo = useMediaQuery('(max-width:1080px)');

  const handleCloseNavMenu = (cat) => {
    navigate(`/demo/${cat}`);
  };

  const categories = [
    { label: 'Live Sports', path: 'live-sports' },
    { label: 'Entertainment', path: 'entertainment' },
    { label: 'News', path: 'news' },
    { label: 'Stream Extension', path: 'stream-extension' },
    { label: 'Enhanced CTV', path: 'enhanced-ctv' },
  ];
  const createYourOwnPath = matchPath(
    {
      path: '/demo/create-your-own',
      end: false,
    },
    pathname
  );

  return (
    <section className={styles.demoScreenWrapper}>
      <div className={classNames(styles.flex, styles.flexCol, styles.fullWidth, styles.demoContentWrapper)}>
        <div style={{ marginBottom: 34, textAlign: 'center' }}>
          <h2 className={styles.heading}>Demos</h2>
        </div>

        <div className={classNames(styles.demoSectionContainer)}>
          <div
            className={tabbedStyles.tabbedSection}
            style={{ padding: 0, ...((!tabbedSectionOverflowing && { alignItems: 'center' }) || {}) }}
            ref={tabbedSectionRef}
          >
            <div className={tabbedStyles.tabButtons}>
              <>
                {categories.map(({ label, path }) => (
                  <Button
                    key={label}
                    onClick={() => handleCloseNavMenu(path)}
                    className={classNames(
                      styles.button,
                      pathname.startsWith(`/demo/${path}`) ? tabbedStyles.activeTabBtn : ''
                    )}
                  >
                    {label}
                  </Button>
                ))}
                {unsupportedScreenExtensionDemo ? null : (
                  <Button
                    onClick={() => navigate('/demo/create-your-own/upload-content')}
                    className={classNames(styles.button, createYourOwnPath ? tabbedStyles.activeTabBtn : '')}
                  >
                    <span>Create your own</span>
                  </Button>
                )}
              </>
            </div>
          </div>

          <Outlet />
        </div>
      </div>
    </section>
  );
};

Demo.defaultProps = {};
Demo.propTypes = {};

export default Demo;
