import React from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Box, Button, Dialog, DialogContent, DialogTitle, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { baseButton, keyButton, secondaryButton, newFieldStyling, actionBar } from '../styles/styles';

/** @type {Object<string, import('@mui/styles').CSSProperties>} */
const styles = {
  newFieldStyling,
  baseButton,
  keyButton,
  secondaryButton,
  actionBar,
  setting: {
    display: 'flex',
    flexDirection: 'column',
    gap: '15px',
  },
  contentText: {
    lineHeight: '1.2em',
  },
};

const useStyles = makeStyles(styles);

const ConfirmationDialog = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const /** @type {Object} */ {
      disabled = props?.disabled || false,
      title = props?.title || 'Are you sure?',
      contentText = props?.contentText,
      onConfirmClick = props?.onConfirmClick,
      onCancelClick = props?.onCancelClick,
    } = useOutletContext() || {};

  return (
    <Dialog
      open
      className={classNames(classes.newFieldStyling, classes.setting)}
      PaperProps={{
        sx: {
          maxHeight: '30vh',
          borderRadius: '10px',
          maxWidth: '30vw',
        },
      }}
      onClose={() => navigate(-1)}
    >
      {title ? <DialogTitle>{title}</DialogTitle> : null}
      <DialogContent>
        <Typography className={classes.contentText} variant="body1">
          {contentText}
        </Typography>
      </DialogContent>
      <Box className={classes.actionBar}>
        <Button className={classNames(classes.secondaryButton, classes.baseButton)} onClick={onCancelClick}>
          Cancel
        </Button>
        <Button
          className={classNames(classes.keyButton, classes.baseButton)}
          disabled={disabled}
          onClick={onConfirmClick}
        >
          Confirm
        </Button>
      </Box>
    </Dialog>
  );
};

ConfirmationDialog.defaultProps = {
  disabled: false,
  title: 'Are you sure?',
  contentText: {} || '',
  onConfirmClick: () => {},
  onCancelClick: () => {},
};

ConfirmationDialog.propTypes = {
  disabled: PropTypes.bool,
  title: PropTypes.string,
  contentText: PropTypes.shape({}) || PropTypes.string,
  onConfirmClick: PropTypes.func,
  onCancelClick: PropTypes.func,
};

export default ConfirmationDialog;
