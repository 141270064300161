/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import HttpsRedirect from 'react-https-redirect';
import { create } from 'jss';

import { StylesProvider, createGenerateClassName, jssPreset } from '@mui/styles';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';

import { createBrowserRouter, createRoutesFromElements, Navigate, Route, RouterProvider } from 'react-router-dom';
import theme from './styles/theme';
import Home from './components/website/home/home';
import ErrorPage from './components/error-page';
import LoginForm from './components/login/login-form';
import StreamComposer from './components/website/stream-composer/stream-composer';
import ReachExtension from './components/website/reach-extension/reach-extension';
import CreativeStudio from './components/website/creative-studio/creative-studio';
import Careers from './components/website/careers';
import Press from './components/website/press';
import RequestDemo from './components/website/request-demo';
import Privacy from './components/website/privacy';
import Security from './components/website/security';
import Demo from './components/website/demo';
import UserContextProvider from './user-context';
import ComposerToastContextProvider from './components/composer/composer-toast-context';
import AppLayout from './components/website/app-layout';
import ExtensionDemo from './components/website/extension-demo';
import UploadContent from './components/website/extension-demo/upload-content';
import ChooseFormat from './components/website/extension-demo/choose-format';
import Pip from './components/website/extension-demo/choose-format/pip';
import Lbar from './components/website/extension-demo/choose-format/l-bar';
import Overlay from './components/website/extension-demo/choose-format/overlay';
import SelectInsertion from './components/website/extension-demo/select-insertion';
import Share, { action as shareAction } from './components/website/extension-demo/share';
import UnsupportedDevice from './components/website/extension-demo/unsupported-device';
import DemoPlayer from './components/website/demo-player';
import ForgotPassword from './components/login/forgot-password';

const generateClassName = createGenerateClassName();
const jss = create(jssPreset());

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/" element={<AppLayout />}>
        <Route path="stream-composer" element={<StreamComposer />} />
        <Route path="stream-extension" element={<ReachExtension />} />
        <Route path="creative-studio" element={<CreativeStudio />} />
        <Route path="careers" element={<Careers />} />
        <Route path="request-demo" element={<RequestDemo />} />
        <Route path="press" element={<Press />} />
        <Route path="privacy" element={<Privacy />} />
        <Route path="security" element={<Security />} />
        <Route path="demo" element={<Demo />}>
          <Route index element={<Navigate to="sports/pip" />} />
          <Route path="live-sports/*" element={<DemoPlayer category="live-sports" />} />
          <Route path="entertainment/*" element={<DemoPlayer category="entertainment" />} />
          <Route path="news/*" element={<DemoPlayer category="news" />} />
          <Route path="stream-extension/*" element={<DemoPlayer category="stream-extension" />} />
          <Route path="enhanced-ctv/*" element={<DemoPlayer category="enhanced-ctv" />} />
          <Route path="create-your-own" element={<ExtensionDemo />}>
            <Route index element={<Navigate to="upload-content" />} />
            <Route path="upload-content" element={<UploadContent />} />
            <Route path="choose-format" element={<ChooseFormat />}>
              <Route index element={<Navigate to="pip" />} />
              <Route path="pip" element={<Pip />} />
              <Route path="squeezeBack" element={<Lbar />} />
              <Route path="overlay" element={<Overlay />} />
            </Route>
            <Route path="select-insertion" element={<SelectInsertion />} />
            <Route path="share" element={<Share />} action={shareAction} />
            <Route path="unsupported-device" element={<UnsupportedDevice />} />
          </Route>
        </Route>
        <Route index element={<Home />} />
      </Route>
      <Route path="/login" element={<LoginForm />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route
        path="*"
        element={<ErrorPage errorCode={404} description="Sorry, we couldn't find the page you're looking for." />}
      />
    </>
  )
);

export const IndexApp = () => {
  return (
    <StylesProvider jss={jss} generateClassName={generateClassName}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <HttpsRedirect>
            <ComposerToastContextProvider>
              <UserContextProvider>
                <RouterProvider router={router} />
              </UserContextProvider>
            </ComposerToastContextProvider>
          </HttpsRedirect>
        </ThemeProvider>
      </StyledEngineProvider>
    </StylesProvider>
  );
};
