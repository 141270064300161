import { AD_FORMATS, AD_LAYOUTS } from '../../../../services/layout-service';
import { SCENE_NAMES } from '../../scenes/studio-scene-helper';

// transition animation assets
import CircleOpenLottie from '../../../../assets/animations/transitions/circleopen.lottie';
import FadeLottie from '../../../../assets/animations/transitions/fade.lottie';
import FadeBlackLottie from '../../../../assets/animations/transitions/fadeblack.lottie';
import HorzOpenLottie from '../../../../assets/animations/transitions/horzopen.lottie';
import HrSliceLottie from '../../../../assets/animations/transitions/hrslice.lottie';
import SmoothDownLottie from '../../../../assets/animations/transitions/smoothdown.lottie';
import SmoothRightLottie from '../../../../assets/animations/transitions/smoothright.lottie';
import SmoothUpLottie from '../../../../assets/animations/transitions/smoothup.lottie';
import VertOpenLottie from '../../../../assets/animations/transitions/vertopen.lottie';
import VuSliceLottie from '../../../../assets/animations/transitions/vuslice.lottie';
import ZoomInLottie from '../../../../assets/animations/transitions/zoomin.lottie';

export const DEFAULT_INTRO_ANIMATION = 'https://creative.staging.transmit.live/animations/arrowSlider.mp4';
export const MIN_PREVIEW_LOGO_WIDTH = 250;

export const TransitionAnimations = [
  {
    contentType: 'json',
    name: 'Circle Open',
    _id: 'circleopen',
    value: 'circleopen',
    src: CircleOpenLottie,
  },
  {
    contentType: 'json',
    name: 'Fade',
    _id: 'fade',
    value: 'fade',
    src: FadeLottie,
  },
  {
    contentType: 'json',
    name: 'Fade Black',
    _id: 'fadeblack',
    value: 'fadeblack',
    src: FadeBlackLottie,
  },
  {
    contentType: 'json',
    name: 'Horizontal Open',
    _id: 'horzopen',
    value: 'horzopen',
    src: HorzOpenLottie,
  },
  {
    contentType: 'json',
    name: 'Horizontal Right Slice',
    _id: 'hrslice',
    value: 'hrslice',
    src: HrSliceLottie,
  },
  {
    contentType: 'json',
    name: 'Smooth Down',
    _id: 'smoothdown',
    value: 'smoothdown',
    src: SmoothDownLottie,
  },
  {
    contentType: 'json',
    name: 'Smooth Right',
    _id: 'smoothright',
    value: 'smoothright',
    src: SmoothRightLottie,
  },
  {
    contentType: 'json',
    name: 'Smooth Up',
    _id: 'smoothup',
    value: 'smoothup',
    src: SmoothUpLottie,
  },
  {
    contentType: 'json',
    name: 'Vertical Open',
    _id: 'vertopen',
    value: 'vertopen',
    src: VertOpenLottie,
  },
  {
    contentType: 'json',
    name: 'Vertical Up Slice',
    _id: 'vuslice',
    value: 'vuslice',
    src: VuSliceLottie,
  },
  {
    contentType: 'json',
    name: 'Zoom In',
    _id: 'zoomin',
    value: 'zoomin',
    src: ZoomInLottie,
  },
];

export const ASSET_TYPES = {
  skin: { key: 'skin', label: 'Background Skins' },
  overlaySkin: { key: 'overlaySkin', label: 'Overlay Skins' },
  previewLogo: { key: 'previewLogo', label: 'Preview Logo' },
  transition: { key: 'transition', label: 'Slates' },
  transitionAnimation: { key: 'transitionAnimation', label: 'Transition Animations' },
  video: { key: 'video', label: 'Videos' },
  videoThumbnail: { key: 'videoThumbnail', label: 'Video Thumbnails' },
  overlay: { key: 'overlay', label: 'Overlays' },
  previewInventory: { key: 'previewInventory', label: 'Preview Clips' },
};

export const adTypeToAssetTypesMap = {
  [AD_FORMATS.pip.key]: {
    [ASSET_TYPES.skin.key]: true,
    [ASSET_TYPES.overlaySkin.key]: true,
    [ASSET_TYPES.previewLogo.key]: true,
    [ASSET_TYPES.video.key]: true,
    [ASSET_TYPES.transition.key]: true,
    [ASSET_TYPES.transitionAnimation.key]: true,
    [ASSET_TYPES.previewInventory.key]: true,
  },
  [AD_FORMATS.overlay.key]: {
    [ASSET_TYPES.overlay.key]: true,
    [ASSET_TYPES.transition.key]: true,
    [ASSET_TYPES.transitionAnimation.key]: true,
    [ASSET_TYPES.previewInventory.key]: true,
    [ASSET_TYPES.previewLogo.key]: true,
  },
  [AD_FORMATS.squeezeBack.key]: {
    [ASSET_TYPES.skin.key]: true,
    [ASSET_TYPES.overlaySkin.key]: true,
    [ASSET_TYPES.transition.key]: true,
    [ASSET_TYPES.transitionAnimation.key]: true,
    [ASSET_TYPES.previewInventory.key]: true,
    [ASSET_TYPES.previewLogo.key]: true,
  },
  [AD_FORMATS.interstitial.key]: {
    [ASSET_TYPES.video.key]: true,
    [ASSET_TYPES.previewLogo.key]: true,
  },
  [AD_FORMATS.enhancedCreative.key]: {
    [ASSET_TYPES.skin.key]: true,
    [ASSET_TYPES.video.key]: true,
    [ASSET_TYPES.previewLogo.key]: true,
  },
};

export const getAssetTypesForAd = ({ format, layoutName }) => {
  if (layoutName === AD_LAYOUTS.enhancedOverlay.name) {
    return {
      [ASSET_TYPES.overlay.key]: true,
      [ASSET_TYPES.video.key]: true,
      [ASSET_TYPES.previewLogo.key]: true,
    };
  }

  return adTypeToAssetTypesMap[format];
};

export const sceneNameToAssetTypesMap = {
  [SCENE_NAMES.bumper]: {},
  [SCENE_NAMES.introSlate]: {
    [ASSET_TYPES.transition.key]: true,
  },
  [SCENE_NAMES.transitionAnimation]: {
    [ASSET_TYPES.transitionAnimation.key]: true,
  },
  [SCENE_NAMES.initialView]: {},
  [SCENE_NAMES.adContent]: {
    [ASSET_TYPES.skin.key]: true,
    [ASSET_TYPES.overlaySkin.key]: true,
    [ASSET_TYPES.video.key]: true,
    [ASSET_TYPES.overlay.key]: true,
    [ASSET_TYPES.previewInventory.key]: true,
    [ASSET_TYPES.previewLogo.key]: true,
  },
  [SCENE_NAMES.outroSlate]: {
    [ASSET_TYPES.transition.key]: true,
  },
};

export const SHARED_ASSET_TYPES = {
  [ASSET_TYPES.skin.key]: {
    label: 'Skins',
    key: ASSET_TYPES.skin.key,
  },
  [ASSET_TYPES.transition.key]: ASSET_TYPES.transition,
};

export const currentAssetTypeFieldNames = (currentAssetType) => {
  if (currentAssetType === ASSET_TYPES.overlaySkin.key) {
    return {
      idFieldName: 'overlaySkinId',
      urlFieldName: 'overlaySkinUrl',
    };
  }

  if (currentAssetType === ASSET_TYPES.previewLogo.key) {
    return {
      idFieldName: 'preview_logo_id',
      urlFieldName: 'preview_logo_url',
    };
  }

  return {
    idFieldName: `${currentAssetType}_id`,
    urlFieldName: `${currentAssetType}_url`,
  };
};

export const formatAssetsList = ({ assets, selectedAsset }) => {
  if (selectedAsset) {
    const filteredAssets = assets.filter((asset) => asset._id !== selectedAsset._id);
    return [selectedAsset, ...filteredAssets];
  }

  return assets;
};

export const getSelectedAssets = ({ assets, assetType, selectedAssetsIds }) => {
  const assetId = selectedAssetsIds[`${assetType}Id`];
  return assetId ? assets.find((a) => a._id === assetId) : null;
};

export const getSelectedSlate = ({ assets, currentScene, transitions }) => {
  const slateUrlToFind = currentScene === SCENE_NAMES.introSlate ? 'introUrl' : 'outroUrl';
  const urlToFind = transitions?.[slateUrlToFind];

  return assets.find((a) => a.url === urlToFind);
};

export const getSelectedTransitionAnimation = ({ assets, transitions }) => {
  return assets.find((a) => a.value === transitions?.transitionAnimation);
};

export const getIsTransitionAnimationAssets = (currentAssetType) => {
  return currentAssetType === ASSET_TYPES.transitionAnimation.key;
};

export const getInitialSelectedAsset = ({ currentAssetType, selectedAssets, activeSceneCard, fetchedAssets }) => {
  if (currentAssetType === ASSET_TYPES.transition.key) {
    const slateUrlToFind = activeSceneCard?.name === SCENE_NAMES.introSlate ? 'introUrl' : 'outroUrl';
    const slateUrl = selectedAssets[slateUrlToFind];
    return fetchedAssets.find((a) => a.url === slateUrl);
  }
  if (currentAssetType === ASSET_TYPES.transitionAnimation.key) {
    return getSelectedTransitionAnimation({
      assets: fetchedAssets,
      transitions: { transitionAnimation: selectedAssets?.transitionAnimation },
    });
  }

  return selectedAssets ? selectedAssets[currentAssetType] : null;
};

export const getIsTransitionSceneCard = (name) => name === SCENE_NAMES.transitionAnimation;

export const getTransitionAnimationPreviewUrl = (animationValue) => {
  const transitionAnimation = TransitionAnimations.find((animation) => animationValue === animation.value);
  return transitionAnimation?.src;
};

export const getAffectedEntitiesMessage = (affected) => {
  if (affected.length) {
    const affectedSupplyRoutes = [];
    const affectedCreatives = [];

    for (const a of affected) {
      if (a.associationType === 'supplyRoute') {
        affectedSupplyRoutes.push(a.name);
      } else {
        affectedCreatives.push(a.name);
      }
    }

    const affectedSupplyRoutesList = affectedSupplyRoutes.length
      ? `\nSupply Routes${affectedSupplyRoutes.reduce((acc, n) => `${acc}\n  - ${n}`, '')}`
      : '';
    const affectedCreativesList = affectedCreatives.length
      ? `\nCreatives${affectedCreatives.reduce((acc, n) => `${acc}\n - ${n}`, '')}`
      : '';
    const affectedNames = `${affectedSupplyRoutesList}${affectedCreativesList}`;

    return `${
      `This asset is currently selected for ${affected.length} creative(s) or supply route(s). ` +
      'It cannot be deleted until it is unassociated from the following:'
    }${affectedNames}`;
  }

  return null;
};

const getSelectedAssetsTypeId = (selectedAssetsIds) => {
  return Object.entries(selectedAssetsIds).reduce((acc, [key, id]) => {
    if (id) {
      const [currAssetType] = key.split('Id');
      return [...acc, [currAssetType, id]];
    }

    return acc;
  }, []);
};

export const extractAssetIdsFromAd = (ad) => {
  const {
    preview_logo_id: previewLogoId,
    overlay_id: overlayId,
    skin_id: skinId,
    overlaySkinId,
    video_id: videoId,
  } = ad;

  const selectedAssetsIds = {
    previewLogoId,
    overlayId,
    skinId,
    overlaySkinId,
    videoId,
  };
  const selectedAssetsTypeId = getSelectedAssetsTypeId(selectedAssetsIds);
  return selectedAssetsTypeId;
};
