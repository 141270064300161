import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Box, FormControl, MenuItem, Select } from '@mui/material';

import styles from './site.module.css';

const pageGroups = {
  'live-sports': [
    {
      name: 'Picture-in-Picture',
      page: 'pip',
      src: 'https://tl-open-content.s3.us-east-1.amazonaws.com/web-assets/live-sports-GoPuff.webm',
    },
    {
      name: 'Vertical PiP',
      page: 'vertical-pip',
      src: 'https://tl-open-content.s3.us-east-1.amazonaws.com/web-assets/live-sports-ReesesPuffs.webm',
    },
    {
      name: 'L-Bar Squeeze-Back',
      page: 'l-bar-squeeze-back',
      src: 'https://tl-open-content.s3.us-east-1.amazonaws.com/web-assets/live-sports-Nutrafol.webm',
    },
    {
      name: 'Border Frame',
      page: 'border-frame',
      src: 'https://tl-open-content.s3.us-east-1.amazonaws.com/web-assets/live-sports-Statefarm.webm',
    },
  ],
  entertainment: [
    {
      name: 'Sponsored Overlay',
      page: 'sponsored-overlay',
      src: 'https://tl-open-content.s3.amazonaws.com/web-assets/demo-entertainment-sponsored-overlay-optimized.mp4',
    },
    {
      name: 'Subscription Overlay',
      page: 'subscription-overlay',
      src: 'https://tl-open-content.s3.amazonaws.com/web-assets/demo-entertainment-subscription-overlay-optimized.mp4',
    },
    {
      name: 'Stream Session Sponsorship',
      page: 'stream-session-sponsorship',
      src: 'https://tl-open-content.s3.amazonaws.com/web-assets/demo-entertainment-stream-session-sponsorship-optimized.mp4',
    },
  ],
  news: [
    {
      name: 'Overlay',
      page: 'overlay',
      src: 'https://tl-open-content.s3.amazonaws.com/web-assets/demo-news-overlay-optimized.mp4',
    },
    {
      name: 'Border Frame',
      page: 'boreder-frame',
      src: 'https://tl-open-content.s3.amazonaws.com/web-assets/demo-news-border-frame-optimized.mp4',
    },
  ],
  'stream-extension': [
    {
      name: 'Game Day Highlighter',
      page: 'game-day-highlighter',
      src: 'https://tl-open-content.s3.us-east-1.amazonaws.com/web-assets/stream-extension-CBS_ADIDAS.webm',
    },
    {
      name: 'Next On',
      page: 'next-on',
      src: 'https://tl-open-content.s3.amazonaws.com/web-assets/demo-extension-next-on-optimized.mp4',
    },
  ],
  'enhanced-ctv': [
    {
      name: 'Centered',
      page: 'centered',
      src: 'https://tl-open-content.s3.us-east-1.amazonaws.com/web-assets/enhanced-ctv-GUSTO.webm',
    },
    {
      name: 'Offset',
      page: 'offset',
      src: 'https://tl-open-content.s3.us-east-1.amazonaws.com/web-assets/enhanced-ctv-geico.webm',
    },
  ],
};

const DemoPlayer = ({ category }) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  React.useEffect(() => {
    const hasCategoryPage = pathname.split('/').filter(Boolean).length >= 3;
    if (!hasCategoryPage) {
      const [{ page: categoryPage }] = pageGroups[category];
      navigate(categoryPage);
    }
  }, [category]);

  const pages = pageGroups[category] || pageGroups[Object.keys(pageGroups)[0]] || [];

  const src = pages.find(({ page }) => {
    return page === pathname.split('/').pop();
  });
  const { src: videoSrc, name: pageName } = src || pages[0] || {};

  return (
    <div className={classNames(styles.mt, styles.flex, styles.justifyCenter)}>
      <Box className={styles.demoPlayerContainer}>
        <div className={styles.demoAdFormatSelectWrapper}>
          <div className={styles.demoAdFormatSelectContainer}>
            <p>Ad Format:</p>
            <FormControl fullWidth>
              <Select className={styles.demoAdFormatSelect} id="demo-simple-select" value={pageName}>
                {pages.map(({ name, page, src: currentSrc }) => {
                  return (
                    <MenuItem
                      key={page}
                      selected={videoSrc === currentSrc}
                      onClick={() => navigate(`/demo/${category}/${page}`)}
                      value={name}
                    >
                      {name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </div>
        </div>

        <Box sx={{ width: '100%' }} className={classNames(styles.mt2, styles.flex, styles.justifyCenter)}>
          <div style={{ height: 'auto', width: '100%', maxWidth: 810, maxHeight: 456 }}>
            {videoSrc ? (
              <video
                style={{ borderRadius: 24 }}
                src={videoSrc}
                width="100%"
                height="100%"
                loop
                playsInline
                autoPlay
                muted
                controls
              />
            ) : (
              <Box />
            )}
          </div>
        </Box>
      </Box>
    </div>
  );
};

DemoPlayer.propTypes = {
  category: PropTypes.string.isRequired,
};

export default DemoPlayer;
